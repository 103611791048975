import { useState, useEffect } from 'react'

const useToCVisible = () => {
    let [scrolledY, setScrolledY] = useState(0)
    let [scrolledYArticleBody, setScrolledYArticleBody] = useState(0)
    const [toCVisible, toggleVisibility] = useState(false)

    const handleScroll = () => {
        const winScroll = window.scrollY;
        // const articleHeaderHeight = document.getElementById("article-header-carl").clientHeight
        const articleBodyHeight = document.getElementById("article-body-carl").clientHeight
        const bodyHeight =
          document.body.clientHeight - document.documentElement.clientHeight
        const scrolledPerecent = (winScroll / bodyHeight) * 100
        setScrolledY(scrolledPerecent);

        const scrolledArticleBodyPerecent = (winScroll / articleBodyHeight) * 100
        setScrolledYArticleBody(scrolledArticleBodyPerecent)
    };

    useEffect(() => {
        const toggle = () => {
            if (scrolledY > 5 && scrolledYArticleBody < 95) {
                toggleVisibility(true)
            } else {
                toggleVisibility(false)
            }
        }

        if (typeof document !== 'undefined') {
            toggle()
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [scrolledY, scrolledYArticleBody]);

    return toCVisible;
}

export default useToCVisible
