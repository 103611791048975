import { useState, useEffect } from 'react'

const useToolVisible = () => {
    let [scrolledY, setScrolledY] = useState(0)
    const [toolVisible, toggleVisibility] = useState(false)

    const handleScroll = () => {
        const winScroll = window.scrollY;
        const bodyHeight =
          document.body.clientHeight - document.documentElement.clientHeight
        const scrolledPerecent = (winScroll / bodyHeight) * 100
        setScrolledY(scrolledPerecent);
    };

    useEffect(() => {
        const toggle = () => {
            if (scrolledY > 12 && scrolledY < 70) {
                toggleVisibility(true)
            } else {
                toggleVisibility(false)
            }
        }

        if (typeof document !== 'undefined') {
            toggle()
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [scrolledY]);

    return toolVisible;
}

export default useToolVisible
