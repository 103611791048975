import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const ModalDashboard = ({ onClose, title, url, wide }) => {
  const background = React.createRef();
  const [visible, toggleVisibility] = useState(true);

  const handleClick = event => {
    event.preventDefault();
    toggleVisibility(false);
    setTimeout(onClose, 500);
  };

  return (
    <div
      className={classNames({
        'text-black-900 fixed inset-0 flex align-middle justify-center z-30 mx-auto transform transition-all ease-in-out duration-500 animate-opacity-0-to-1': true,
        'opacity-100': visible,
        'opacity-0': !visible,
      })}
      role="dialog"
    >
      <div
        className={classNames({
          'w-full lg:mr-6 lg:ml-6 h-screen my-auto z-50 px-8 pt-2 pb-16 shadow-2xl bg-grey-900.95 transform transition-all duration-500 ease-in-out sm:px-12 animate-translate-y-top-full-animation': true,
          'translate-y-0': visible,
          '-translate-y-full': !visible,
        })}
      >
        <button
          onClick={handleClick}
          className="block ml-auto text-5xl font-light transform transition-colors duration-150 active:text-blue-200 focus:outline-none hover:text-blue-500"
        >
          <span className="text-xl mr-6">Erfahren Sie mehr &#10132;</span>
          ×
        </button>
        <div className="overflow-hidden aspect-h-16 aspect-w-9 lg:aspect-w-4 lg:aspect-h-4">
            <iframe
                title={title}
                src={url} 
                className=""
                frameBorder="0"                
                scrolling="no" 
                allowFullScreen
                data-service="public.tableau">
            </iframe>
        </div>
      </div>
      <button
        className="fixed w-full z-30 bg-grey-500.5 block inset-0 outline-none cursor-default"
        onMouseDown={handleClick}
        ref={background}
        aria-label="Close modal"
      />
    </div>
  );
};

ModalDashboard.propTypes = {
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  wide: PropTypes.bool,
};

export default ModalDashboard;
