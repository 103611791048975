import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'
import { Location } from '@reach/router'

import ModalDashboardTrigger from '../HOC/ModalDashboardTrigger';
import useWindowWidth from '../hooks/useWindowWidth'
import TertiaryLink from './UIComponents/TertiaryLink'
import ArticleTool from '../components/ArticleTool'

const ArticleBody = ({ author, body, tool }) => {
  const width = useWindowWidth()

  return (
    <section id="article-body-carl" className="my-6  sm:my-10 lg:my-10">
      {body.map((row, index) => {
        const imageIndex = row.articleContentRow.findIndex(entry => entry.typeHandle === 'image');
        var imageSrc = null
        if (row.articleContentRow[imageIndex]) {
          if (width <= 640) {
            imageSrc = row.articleContentRow[imageIndex].sourceMobile[0] ? row.articleContentRow[imageIndex].sourceMobile[0].url : row.articleContentRow[imageIndex].source[0].url 
          } else {
            imageSrc = row.articleContentRow[imageIndex].source[0].url
          }
        }
        
        return (imageIndex !== -1) ? (
          <div className="mx-auto max-w-screen-xxl grid grid-cols-1 lg:col-gap-20 lg:grid-cols-article mb-8 lg:mb-15" key={row.id}>
            <div className="lg:col-start-2 lg:col-end-3">
              <img
                src={imageSrc}
                alt={row.articleContentRow[imageIndex].caption || 'Article image'}
                className="shadow-md w-full max-w-screen-xxl mx-auto object-center object-cover "
                loading="lazy"
              />
            </div>            
          </div>
        ) : (
          <div key={row.id} className="px-5 md:px-10">
            <div className="mx-auto max-w-screen-xxl grid grid-cols-1 lg:col-gap-20 lg:grid-cols-article">
              {(index===1) || (index===4) ? 
                <div className="flex md:hidden">
                  <ArticleTool tool={tool} />
                </div> 
              : null}
              
              {row.articleContentRow.map(entry => {
                switch (entry.typeHandle) {
                  case 'headline':
                    var idForAnchor = entry.text.split(' ').join('-');
                    return (
                      <h2
                        key={entry.id}
                        id={idForAnchor}
                        className="text-2.5xl leading-8 mb-4 font-normal lg:leading-12 lg:text-4.5xl lg:col-start-2 lg:col-end-3"
                      >
                        {ReactHtmlParser(entry.text)}
                      </h2>
                    )
                  case 'contentText':
                    return (
                      <span
                        key={entry.id}
                        className="text-15 mb-6 custom-article-content sm:mb-8 md:text-17 lg:mb-16 lg:col-start-2 lg:col-end-3 xl:text-xl"
                      >
                        {ReactHtmlParser(entry.text)}
                      </span>
                    )
                  case 'richText':
                      return (
                        <div
                          key={entry.id}
                          className="text-15 mb-6 custom-article-content sm:mb-8 md:text-17 lg:mb-16 lg:col-start-2 lg:col-end-3 xl:text-xl"
                        >
                          {ReactHtmlParser(entry.text)}
                        </div>
                      )
                  case 'citation':
                    return (
                      <div
                        className="flex flex-col mb-6 lg:col-start-2 lg:col-end-3 bg-grey-50 py-6 md:py-12 md:px-8"
                        key={entry.id}
                      >
                        <p className="italic text-28 leading-9 md:text-4.75xl md:leading-12">
                          "{entry.text}"
                        </p>
                        <div className="self-start mt-6">
                          <p className="text-16">{entry.author}, {entry.jobTitle}</p>
                        </div>
                      </div>
                    )
                  case 'teaser':
                    return (
                      <div
                        className="text-15 flex flex-col mb-6 sm:mb-8 md:text-17 lg:col-start-3 lg:col-end-4"
                        key={entry.id}
                      >
                        <span className="mb-4 leading-8">
                          {ReactHtmlParser(entry.teaserText)}
                        </span>
                        <TertiaryLink
                          text="Mehr Dazu"
                          url={`/${entry.teaserLink}`}
                        />
                      </div>
                    )
                  case 'moreBlock':
                    return (
                      <div
                        className="flex flex-col mb-6 lg:col-start-2 lg:col-end-3 bg-grey-50 pt-2 md:pt-6 pb-2 px-2 md:px-6"
                        key={entry.id}
                      >
                        <p className="text-2xl mb-4 leading-9 md:text-3.5xl md:leading-12">
                          {entry.moreTitle}
                        </p>
                        <span
                          key={entry.id}
                          className="text-15 custom-article-content md:text-17 xl:text-xl"
                        >
                          {ReactHtmlParser(entry.moreText)}
                        </span>
                      </div>
                    )
                  case 'iframe':
                    return (
                      <Location>
                        {({ location }) => (
                          <ModalDashboardTrigger
                            key={entry.id}
                            wide={false}
                            location={location}
                            url={entry.url}
                            title={entry.id}
                            className="text-15 mb-6 custom-article-content sm:mb-8 md:text-17 lg:mb-16 lg:col-start-2 lg:col-end-3 xl:text-xl"
                          >
                            <span className="cursor-pointer link link-padded transform ease-in-out duration-150">
                              <p>Zum interaktiven Dashboard</p>
                              <img
                                src={entry.previewImage[0]?.url}
                                alt={entry.id}
                                className="w-full object-left object-cover h-80 sm:h-100 xxl:h-140"
                                loading="lazy"
                              />
                            </span>
                          </ModalDashboardTrigger>
                        )}
                      </Location> 
                    )
                  default:
                    return null
                }
              })}
            </div>
          </div>
        )
      })}
      <div className="mx-auto max-w-screen-xxl grid grid-cols-1 lg:col-gap-20 lg:grid-cols-article mb-8 lg:mb-15 mt-4">
        <div
          className="row-start-1 px-5 row-span-2 mb-4 text-13 lg:flex lg:flex-col lg:col-start-2 lg:col-end-3"
        >
          <hr className="h-px w-full my-3 border-none bg-grey-400 mb-6 lg:mb-4" />
          <div className="flex">
            <img
              src={author?.avatar[0].url}
              alt={author?.nameField}
              className="object-cover object-top h-24 w-24 rounded-full ml-1"
              loading="lazy"
            />
            <div className="flex flex-col ml-4">
              <div>
                <p className="text-23">{author?.nameField}</p>
                <p className="text-16 text-grey-600 mb-2 lg:mb-3">{author?.position}</p>
              </div>
              <div className="hidden sm:block">
                <a 
                  href={author?.uri ? `/${author?.uri}` : null}
                  className="button-primary-grey inline-block mr-2 lg:mr-3 mb-2 lg:mb-3"
                >
                  Mehr erfahren
                </a>
                <a 
                  href={author?.linkedinLink ? author?.linkedinLink : null}
                  className="button-primary-blue inline-block text-blue-300 "
                >
                  Linkedin
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-4 sm:hidden">
              <a 
                href={author?.uri ? `/${author?.uri}` : null}
                className="button-primary-grey inline-block mr-2 lg:mr-3 mb-2 lg:mb-3"
              >
                Mehr erfahren
              </a>
              <a 
                href={author?.linkedinLink ? author?.linkedinLink : null}
                className="button-primary-blue inline-block text-blue-300 "
              >
                Linkedin
              </a>
            </div>
        </div>
      </div>
    </section>
  )
}

ArticleBody.propTypes = {
  author: PropTypes.shape({
    nameField: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    avatar: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    )
  }),
  body: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      articleContentRow: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string,
          typeHandle: PropTypes.string.isRequired
        })
      )
    })
  )
}

export default ArticleBody
