import React from 'react'

import ArticleGridRow from './ArticleGridRow'

const RelatedArticles = ({ related }) => {
  return (
    <section className="px-5 md:px-10">
      <div className="mx-auto max-w-screen-xxl">
        {related.map(gridRow => (
          <ArticleGridRow row={gridRow} key={gridRow.id} isRelatedGridRow={true} />
        ))}
      </div>
    </section>
  )
}

export default RelatedArticles
