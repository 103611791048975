import React, { useState } from 'react'

import ModalDashboard from '../components/ModalDashboard'

const ModalDashboardTrigger = (props) => {
  const params = new URLSearchParams(props.location.search)
  const showOnFirstLoad = params.get("showDashboard")
  const [isModalOpen, toggleModal] = useState(showOnFirstLoad)
  const toggle = () => toggleModal(!isModalOpen)

  return (
    <div className={props.className}>
      {React.cloneElement(props.children, {
        onClick: () => toggle()
      })}
      {isModalOpen && (
        <ModalDashboard isOpen={isModalOpen} onClose={toggle} {...props} />
      )}
    </div>
  )
}

export default ModalDashboardTrigger
