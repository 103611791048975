import React from 'react'
import { format } from 'date-fns'

import ArticleToolSticky from '../components/ArticleToolSticky'
import ArticleTableOfContents from '../components/ArticleTableOfContents'
import ArticleShareButtons from '../components/ArticleShareButtons'
import ArticleBody from '../components/ArticleBody'
import ArticleHeader from '../components/ArticleHeader'
import Banner from '../components/Banner.jsx'
import Layout from '../components/layout'
import RelatedArticles from '../components/RelatedArticles.jsx'
import SEO from '../components/seo'
import FAQSet from '../components/FAQSet.jsx'


export default ({ pageContext }) => {
  const {
    articleAuthor,
    articleContentGrid,
    readingTime,
    postDate,
    lastRevision,
    faqSet,
    related,
    seomatic,
    selectedBanner,
    thumbnailSmall,
    thumbnailMedium,
    thumbnailLarge,
    title,
    url,
    tool
  } = pageContext
  // const date = lastRevision ? `${format(new Date(lastRevision), 'dd.MM.yyyy')}` : `${format(new Date(postDate), 'dd.MM.yyyy')}`
  const date = lastRevision ? `${format(new Date(postDate), 'dd.MM.yyyy')}` : `${format(new Date(postDate), 'dd.MM.yyyy')}`
  const time = `Lesezeit: ${readingTime} min`
  const thumbnails = {
    thumbnailSmall: (thumbnailSmall.length ? thumbnailSmall[0].url : null),
    thumbnailMedium: (thumbnailMedium.length ? thumbnailMedium[0].url : null),
    thumbnailLarge: (thumbnailLarge.length ? thumbnailLarge[0].url : null)
  }

  return (
    <Layout article title={title} url={url}>
      <SEO seomatic={seomatic} />
      <ArticleToolSticky tool={tool} url={url} />
      <ArticleTableOfContents body={articleContentGrid} />
      <ArticleHeader
        title={title}
        dateCreated={date}
        readingTime={time}
        thumbnails={thumbnails}
        author={articleAuthor[0]}
      />
      <ArticleShareButtons
        readingTime={time}
        title={title} 
        quote={""} 
        url={ url }
      />
      <ArticleBody 
        body={articleContentGrid} 
        author={articleAuthor[0]}
        tool={tool}
      />
      {selectedBanner.length ? 
          <Banner banner={selectedBanner} />
          : null}
      {faqSet.length ? 
          <FAQSet faqSet={faqSet} />
          : null}
      <RelatedArticles related={related} />
    </Layout>
  )
}
