import React from 'react'

import {
  EmailShareButton,
} from 'react-share'

import EmailIcon from '../assets/icons/mail.svg';
import ShareIcon from '../assets/icons/share.svg';

const ArticleShareButtons = ({ readingTime, title, url, quote }) => {
  return (
    <div id="article-share-buttons-carl" className="max-w-screen-xxl mx-auto mt-6 sm:mt-10 lg:mt-10 grid grid-cols-1 lg:col-gap-20 lg:grid-cols-article">
        <div className="px-5 md:px-5 xxl:px-0 lg:col-start-2 lg:col-end-3">
            {/* <div className="grid gap-y-2 grid-cols-1 lg:grid-cols-2 lg:col-gap-6"> */}
            <div className="flex flex-row flex-wrap">
                <button
                    onClick={() => {navigator.clipboard.writeText(url)}}
                    className="rounded-full mb-2 md:mb-0 focus:bg-cyan-600 focus:outline-none hover:opacity-75 transform duration-150"
                >
                    <div className="rounded-full text-grey-600 pr-4 h-8 md:h-10 flex items-center lg:justify-center border-black border-2">
                            <ShareIcon className="mx-4" />
                            Link kopieren
                    </div>
                </button>
                <EmailShareButton
                    url={url}
                    body={quote ? quote : ""}
                    subject={title}
                    className="mr-5 focus:outline-none hover:opacity-75 transform duration-150"
                >
                    <div className="rounded-full text-grey-600 ml-0 lg:ml-2 pr-4 h-8 md:h-10 flex items-center lg:justify-center border-black border-2">
                            <EmailIcon className="mx-4" />
                            Via E-Mail senden
                    </div>
                </EmailShareButton>
                
            </div>
        </div>
      </div>
  )
}

export default ArticleShareButtons
