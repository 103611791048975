import React from 'react'
import classNames from 'classnames'

import MobileToCExpander from './MobileToCExpander';
import useProgressBar from '../hooks/useProgressBar'
import useWindowWidth from '../hooks/useWindowWidth'
import { isTouchDevice } from '../utils/generalUtils'
import useToCVisible from '../hooks/useToCVisible'
import useActiveId from '../hooks/useActiveId'

const DesktopTableOfContents = ({ body, headlinesArray, toCVisible, activeId }) => {
    return (
        <div className="max-w-screen-xxl mx-auto">
            <div className="fixed w-1/4 xxxl:w-78 py-10 overflow-y-auto hidden lg:block px-10 xxxl:px-0">
                { body.length > 0 ?
                    <div
                        className={classNames(
                        'flex w-full items-center transition-all duration-200',
                        {
                            'opacity-100': toCVisible,
                            'opacity-0': !toCVisible
                        }
                        )}
                    >
                        <div className='border-1 border-grey-400 bg-white'>
                            <div className='py-4 px-4 text-xl md:leading-6 md:text-xl'>
                                Inhaltsübersicht
                            </div> 
                            {headlinesArray.map(headline => {
                                return(
                                    <div
                                        key={headline.id}
                                    >
                                        <a 
                                            href={`#${headline.id}`}
                                            className={classNames(
                                            'flex px-4 py-2 text-sm hover:bg-grey-250',
                                            {
                                                'bg-cyan-25': activeId === headline.id,
                                                'text-black': activeId !== headline.id
                                            }
                                            )}
                                        >
                                            {headline.text}
                                        </a>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                : null }
                
            </div>
        </div>
    )
}

const MobileTableOfContents = ({ body, headlinesArray }) => {
    useProgressBar()

    return (
        <div className="fixed bg-white w-full top-30 z-10 border-b-1 border-grey-400">
            { body.length > 0 ?
                <div
                    className='flex w-full items-center transition-all duration-200'
                >
                    <div className="bg-white w-full px-5 md:hidden">
                        <MobileToCExpander title="Inhaltsübersicht" headlinesArray={headlinesArray}>
                            
                        </MobileToCExpander>
                    </div>
                    
                </div>
            : null }
            <div
                className={classNames(
                'bg-cyan-600 max-w-screen-xxl w-0',
                {
                    'opacity-100': true,
                    'opacity-0': !true
                }
                )}
                id="progress-bar"
                style={{ height: '4px' }}
                />
        </div>
    )
}

const ArticleTableOfContents = ({ body }) => {
    const toCVisible = useToCVisible()
    const width = useWindowWidth()
    const isTouch = isTouchDevice()

    let headlinesArray = []
    for (var contentObjet of body) {
        for (var entry of contentObjet.articleContentRow){
            if(entry.typeHandle === "headline"){
                var idForAnchor = entry.text.split(' ').join('-');
                headlinesArray.push({text:entry.text, id:idForAnchor})
            }
        }
    }

    const activeId = useActiveId(headlinesArray)

    return (
        <div className='w-full'>
            {width > 1366 || (width > 1024 && !isTouch) ? (
                <DesktopTableOfContents body={body} headlinesArray={headlinesArray} toCVisible={toCVisible} activeId={activeId} />
            ) : (
                <MobileTableOfContents body={body} headlinesArray={headlinesArray} />
            )}
            
        </div>
    )
}

export default ArticleTableOfContents
