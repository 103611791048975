import React, { useState, useEffect } from 'react'
// import { useLocation } from '@reach/router';

const ArticleTool = ({ tool, url }) => {
    const [hrefUnternehmensbewertung, sethrefUnternehmensbewertung] = useState("https://rechner.carlfinance.de/");
    const [hrefUnternehmenssteuer, sethrefUnternehmenssteuer] = useState("https://steuern.nachfolge.de/");
    const [hrefUnternehmensverkauf, sethrefUnternehmensverkauf] = useState("https://verkaufsanfrage.carlfinance.de/");
    
    // transfer utms
    useEffect(() => {
        if (typeof window !== "undefined") {
            if(hrefUnternehmenssteuer.includes("?") === false) {

                if(window.location.search.length > 0) {
                    var params = new URLSearchParams(window.location.search);
                    params.set("page_id", url)
        
                    let newHrefUnternehmensbewertung = hrefUnternehmensbewertung + "?" + params.toString()
                    sethrefUnternehmensbewertung(newHrefUnternehmensbewertung)
                    let newHrefUnternehmenssteuer = hrefUnternehmenssteuer + "?" + params.toString()
                    sethrefUnternehmenssteuer(newHrefUnternehmenssteuer)
                    let newHrefUnternehmensverkauf = hrefUnternehmensverkauf + "?" + params.toString()
                    sethrefUnternehmensverkauf(newHrefUnternehmensverkauf)
                } else {
                    var paramsClean = new URLSearchParams();
                    paramsClean.set("page_id", url)
        
                    let newHrefUnternehmensbewertung = hrefUnternehmensbewertung + "?" + paramsClean.toString()
                    sethrefUnternehmensbewertung(newHrefUnternehmensbewertung)
                    let newHrefUnternehmenssteuer = hrefUnternehmenssteuer + "?" + paramsClean.toString()
                    sethrefUnternehmenssteuer(newHrefUnternehmenssteuer)
                    let newHrefUnternehmensverkauf = hrefUnternehmensverkauf + "?" + paramsClean.toString()
                    sethrefUnternehmensverkauf(newHrefUnternehmensverkauf)
                }
            }
        } 
    }, [hrefUnternehmenssteuer, hrefUnternehmensbewertung, hrefUnternehmensverkauf, url]);

    return (
    <>
    {(() => {
        switch(tool[0]?.title) {
            case 'Unternehmensbewertung':
                return (
                    <div className="bg-grey-90 border-1 border-grey-400 mb-8 lg:mb-0">
                        <div className="hidden md:flex justify-center py-2 mb-4 bg-grey-350">
                            <div className="w-40 h-40 bg-white rounded-full ">
                                <img 
                                    src="https://storage.googleapis.com/dev-cms-storage/article-thumbs/iPhone_valuation_small2.png" 
                                    alt="" 
                                    className="w-full mx-auto object-center object-cover">
                                </img>
                            </div>
                            
                        </div>
                        <div className="px-2 pt-4 md:pt-0">
                            <p className="text-xl md:text-16 md:font-semibold mb-1">
                                Lassen Sie Ihren Unternehmenswert ermitteln
                            </p>
                            <p className="text-16 text-grey-600 mb-8">
                                Machen Sie den ersten Schritt zur Sicherung Ihres Lebenswerks.
                            </p>
                            <a 
                                className="button-primary inline-block mb-4"
                                href={hrefUnternehmensbewertung}
                                id="sticky-rating" 
                            >
                                Zur Unternehmens&shy;bewertung
                            </a>
                        </div>
                    </div>
                )
            case 'Steuerrechner':
                return (
                    <div className="bg-grey-90 border-1 border-grey-400 mb-8 lg:mb-0">
                        <div className="hidden md:flex py-2 mb-4 bg-grey-350">
                            <img 
                                src="https://storage.googleapis.com/dev-cms-storage/article-thumbs/iPad-Air.png" 
                                alt="" 
                                className="w-full mx-auto object-center object-cover">
                            </img>        
                        </div>
                        <div className="px-2 pt-4 md:pt-0">
                            <p className="text-2xl sm:text-xl mb-1">
                                Berechnen Sie die Steuerlast Ihres Unternehmens
                            </p>
                            <p className="text-16 text-grey-600 mb-8">
                                Finden Sie heraus, ob Sie zu viele Steuern zahlen, indem Sie hier Ihre Steuerlast ermitteln lassen.
                            </p>
                            <a 
                                className="button-primary inline-block mb-4"
                                href={hrefUnternehmenssteuer}
                                id="sticky-tax" 
                            >
                                Zum Steuer&shy;rechner
                            </a>
                        </div>
                    </div>
                )
            case 'Verkaufsanalyse':
                return (
                    <div className="bg-grey-90 border-1 border-grey-400 mb-8 lg:mb-0">
                        <div className="hidden md:flex mb-4 bg-grey-350">
                            <img 
                                src="https://storage.googleapis.com/dev-cms-storage/article-thumbs/garnn_small.jpg" 
                                alt="" 
                                className="w-full mx-auto object-center object-cover">
                            </img>
                        </div>
                        <div className="px-2 pt-4 md:pt-0">
                            <p className="text-2xl sm:text-xl mb-1">
                                Starten Sie Ihre persönliche Verkaufsanalyse
                            </p>
                            <p className="text-16 text-grey-600 mb-8">
                            Wir führen eine vertrauliche und unverbindliche Verkaufsanalyse für Sie durch.
                            </p>
                            <a 
                                className="button-primary inline-block mb-4"
                                href={hrefUnternehmensverkauf}
                                id="sticky-sell"
                            >
                                Zur Verkaufs&shy;analyse
                            </a>
                        </div>
                    </div>
                )
            default:
                return null
        }
    })()}         
    </>
    )
}

export default ArticleTool
