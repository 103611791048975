import React from 'react'
import PropTypes from 'prop-types'

import useWindowWidth from '../hooks/useWindowWidth'

import CalendarIcon from '../assets/icons/calendar.svg';
import TimeIcon from '../assets/icons/time.svg';

const ArticleHeader = ({
  author,
  dateCreated,
  readingTime,
  thumbnails,
  title
}) => {
  const width = useWindowWidth()
  let thumbnail = thumbnails.thumbnailSmall
  if (width > 640) {
    thumbnail = thumbnails.thumbnailMedium
  }

  return (
    <section id="article-header-carl" className="bg-grey-50 pt-0 pb-0 md:pt-12 md:pb-12">
      <div
        className="relative w-full z-20 bg-grey-50"
      >
        <div className="max-w-full lg:max-w-screen-xxl mx-auto grid grid-cols-1 sm:grid-cols-2 text-13">
          <div className="row-start-2 sm:row-start-2 lg:row-start-1 h-full flex align-middle px-5 md:px-10 cxl:px-0 ">
            <div className="flex flex-col m-auto max-w-full lg:max-w-screen-xxl">
              <div className="order-3 sm:order-1 flex flex-col sm:flex-row pb-6 md:pb-0">
                <div className="flex mt-4 sm:mt-0">
                  <div className="rounded-full px-4 text-grey-600 text-16 h-8 md:h-10 flex items-center border-black border-2">
                    <CalendarIcon className="mr-4" />
                    {dateCreated}
                  </div>
                </div>
                <div className="flex">
                  <div className="rounded-full ml-0 sm:ml-2 mt-2 sm:mt-0 px-4 text-grey-600 text-16 h-8 md:h-10 flex items-center border-black border-2">
                    <TimeIcon className="mr-4" />
                    {readingTime}
                  </div>
                </div>
              </div>
              <h1 className="order-1 sm:order-2 my-0 md:my-6 text-2.5xl leading-10 font-bold break-words md:text-4.5xl md:leading-12">
                {title}
              </h1>
              {author ? 
                <div className="order-2 sm:order-3 flex mt-4">
                  <a 
                    href={author?.uri ? `/${author?.uri}` : null}
                    className="rounded-full mb-2 md:mb-0 hover:opacity-75 transform duration-150"
                  >
                    <div className="rounded-full pr-4 h-18 flex items-center lg:justify-center border-black border-2">
                      <div className="flex">
                        <img
                          src={author?.avatar[0].url}
                          alt={author?.nameField}
                          className="object-cover object-top h-16 w-16 rounded-full ml-1"
                        />
                      </div>
                      <p className="ml-2 text-16 text-cyan-600 underline">
                       {author?.nameField}
                      </p> 
                    </div>
                  </a>
                </div>
              : null}
            </div>
          </div>
          <div className="row-start-1 sm:row-start-2 lg:row-start-1 px-0 md:px-10 cxl:px-0">
            <img
              src={thumbnail}
              alt={""}
              className="ml-auto mr-0 mb-2 h-60 sm:h-100 sm:mb-0 xxl:h-120 object-cover object-center"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

ArticleHeader.propTypes = {
  author: PropTypes.shape({
    nameField: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }),
  dateCreated: PropTypes.string,
  readingTime: PropTypes.string,
  thumbnails: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default ArticleHeader
