import React from 'react'
import classNames from 'classnames'

import ArticleTool from '../components/ArticleTool'
import useToolVisible from '../hooks/useToolVisible'

const ArticleToolSticky = ({ tool, url }) => {
    const toolVisible = useToolVisible()
    
    return (
            <div className="max-w-screen-xxl mx-auto">
                <div className="fixed right-calc-class bottom-0 w-1/4 xxxl:w-78 py-10 overflow-y-auto hidden lg:block px-10 xxxl:px-0">
                    { tool.length > 0 ?
                        <div
                            className={classNames(
                            'flex w-full items-center transition-all duration-200',
                            {
                                'opacity-100': toolVisible,
                                'opacity-0': !toolVisible
                            }
                            )}
                        >
                            <ArticleTool tool={tool} url={url}/>       
                        </div>
                    : null }
                </div>
            </div>
    )
}

export default ArticleToolSticky
