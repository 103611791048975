import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby';

import IosArrowDown from 'react-ionicons/lib/IosArrowDown'
import IosArrowUp from 'react-ionicons/lib/IosArrowUp'

const MobileToCExpander = ({ title, headlinesArray }) => {
  const [visible, toggleVisibility] = useState(false)
  const [mounted, mount] = useState(false)

  const toggle = () => {
    if (!mounted) {
      mount(true)
      toggleVisibility(true)
    } else {
      toggleVisibility(false)
      setTimeout(() => mount(false), 300)
    }
  }

  return (
    <div className="border-b border-white">
      <button
        className="w-full flex cursor-pointer focus:outline-none"
        onClick={toggle}
      >
        <p className="mr-auto py-4 font-bold text-left">{title}</p>
        <span className="ml-auto py-4">
          {mounted ? (
            <IosArrowUp color="black" fontSize="24px" className="mr-1" />
          ) : (
            <IosArrowDown color="black" fontSize="24px" className="mr-1" />
          )}
        </span>
      </button>

      {mounted && (
        <div
          className={`transform transition-all duration-300 ease-in-out ${
            visible ? 'menu-item' : 'menu-item max-h-0 opacity-0'
          }`}
        >
          <div className="pb-5 overflow-hidden">
            <nav>
                <ul className="-mt-3 mb-3 overflow-hidden">
                    {headlinesArray.map(headline => (
                    <li className="mt-4" key={headline.id}>
                    <button
                        className="w-full flex text-left cursor-pointer focus:outline-none"
                        onClick={toggle}
                    >
                        <Link className="text-sm" to={`#${headline.id}`}>{headline.text}</Link>
                    </button>
                    </li>
                    ))}
                </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  )
}

MobileToCExpander.propTypes = {
  title: PropTypes.string.isRequired
}

export default MobileToCExpander
