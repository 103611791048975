import { useEffect, useState } from "react";

function useActiveId(headlinesArray) {
  const [activeId, setActiveId] = useState(``);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` }
    );

    headlinesArray.forEach((headline) => {
      if(document.getElementById(headline.id)){
        observer.observe(document.getElementById(headline.id));
      }
    });

    return () => {
        headlinesArray.forEach((headline) => {
          if(document.getElementById(headline.id)){
            observer.observe(document.getElementById(headline.id));
          }      
        });
    };
  }, [headlinesArray]);

  return activeId;
}

export default useActiveId